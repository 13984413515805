/* eslint-disable react/jsx-one-expression-per-line */
import { LoadingOutlined } from '@ant-design/icons'
import { Card, Col, Divider, Row, Space, Tag } from 'antd'
import { format } from 'date-fns'
import React from 'react'
import { useTicket } from '~/hooks/HelpDesk/useTickets'
import { translate } from '~/utils/locale'

import { Container } from './styles'

const DetailsTicket: React.FC = () => {
  const { ticket, isLoading } = useTicket()

  if (isLoading || !ticket) {
    return <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
  }

  const createdTicket = format(
    new Date(ticket.createdAt),
    `dd/MM/yyyy '${translate('TasksTabsDetails.dateString')}' HH:mm'h'`,
  )

  const dateTicket = format(
    new Date(ticket.date),
    `dd/MM/yyyy '${translate('TasksTabsDetails.dateString')}' HH:mm'h'`,
  )

  return (
    <Container>
      <Row gutter={10}>
        <Col span={14}>
          <Card
            bordered={false}
            title={
              <Space>
                {translate('ClientsDetailsTab.cardGeneralInfoTitle')}
                {ticket.status === 'new' ? <Tag color="green">Novo</Tag> : ''}

                {ticket.status === 'working' ? (
                  <Tag color="blue">Em Atendimento</Tag>
                ) : (
                  ''
                )}

                {ticket.status === 'completed' ? (
                  <Tag color="geekblue">Concluído</Tag>
                ) : (
                  ''
                )}

                {ticket.status === 'canceled' ? (
                  <Tag color="red">Cancelado</Tag>
                ) : (
                  ''
                )}
              </Space>
            }
            style={{
              display: 'inline-block',
              width: '100%',
            }}
          >
            <div className="title">
              <h3>{ticket.title}</h3>
              <p>
                <b>{translate('HelpDeskDetailTab.createdAtLabel')}</b>{' '}
                {createdTicket}
              </p>

              <p>
                <b>{translate('HelpDeskDetailTab.date')}</b> {dateTicket}
              </p>

              <p>
                <b>{translate('HelpDeskDetailTab.priorityLabel')}</b>
                {ticket.priority === 'low' ? (
                  <Tag color="green">Baixa</Tag>
                ) : (
                  ''
                )}

                {ticket.priority === 'medium' ? (
                  <Tag color="yellow">Média</Tag>
                ) : (
                  ''
                )}

                {ticket.priority === 'high' ? <Tag color="red">Alta</Tag> : ''}
              </p>

              <p>
                <b>Status: </b>
                {ticket.status === 'new' ? <Tag color="green">Novo</Tag> : ''}

                {ticket.status === 'working' ? (
                  <Tag color="blue">Em Atendimento</Tag>
                ) : (
                  ''
                )}

                {ticket.status === 'completed' ? (
                  <Tag color="geekblue">Concluído</Tag>
                ) : (
                  ''
                )}

                {ticket.status === 'canceled' ? (
                  <Tag color="red">Cancelado</Tag>
                ) : (
                  ''
                )}
              </p>
            </div>

            <Divider />
            <h3>{translate('HelpDeskDetailTab.typeRequestLabel')}</h3>

            <p>
              <b>{translate('HelpDeskDetailTab.typeRequestTitle')}</b>
              {ticket?.ticketType?.title ? (
                <>{ticket?.ticketType?.title}</>
              ) : (
                'Nenhum tipo de solicitação fornecido'
              )}
            </p>

            <p>
              <b>{translate('HelpDeskDetailTab.typeRequestDescription')}</b>
              {ticket?.ticketType?.description ? (
                <>{ticket?.ticketType?.description}</>
              ) : (
                'Nenhum tipo de solicitação fornecido'
              )}
            </p>

            <Divider />

            <div className="description">
              <h3>{translate('HelpDeskDetailTab.descriptionTitle')}</h3>
              {ticket.description ? (
                <p>{ticket.description}</p>
              ) : (
                <p>Nenhuma descrição fornecida</p>
              )}
            </div>
          </Card>
        </Col>

        <Col span={10}>
          <Card
            bordered={false}
            title={translate('HelpDeskClientTab.clientTabTitle')}
            style={{
              display: 'inline-block',
              width: '100%',
            }}
          >
            <div className="list">
              <p>
                <b>{translate('HelpDeskClientTab.clientLabel')}</b>
                {ticket.customer}
              </p>
              <p>
                <b>{translate('HelpDeskClientTab.emailLabel')}</b>
                {ticket.customer_email}
              </p>
              <p>
                <b>{translate('HelpDeskClientTab.telephoneLabel')}</b>
                {ticket.customer_phone !== 'undefined' ? (
                  <>{ticket.customer_phone}</>
                ) : (
                  'Nenhum telefone fornecido'
                )}
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default DetailsTicket
