/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Result, Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { Container, LoadingIndicator } from './styles'

import ContributorTabs from '~/components/DataManipulation/Contributor/ContributorTabs'

import { useContributor } from '~/hooks/Contributors/useContributor'

import { TaskListProvider } from '~/hooks/Tasks/useListTasks'
import { ListGroupProvider } from '~/hooks/Groups/useListGroup'
import { ListClientsProvider } from '~/hooks/Clients/useListClients'
import { translate } from '~/utils/locale'
import { TeamsProvider } from '~/hooks/Teams/useListTeams'
import { BadgeProvider } from '~/hooks/Badge/useBadge'

const EditContributor: React.FC = () => {
  const { contributorId, contactId } = useParams<{
    contributorId: string
    contactId: string
  }>()

  const {
    setSelectedContributor,
    setSelectedContact,
    data,
    isLoading,
    setIsEdit,
  } = useContributor()

  useEffect(() => {
    setIsEdit(false)
    setSelectedContributor(contributorId)
    setSelectedContact(contactId)
  }, [
    contactId,
    contributorId,
    setSelectedContributor,
    setSelectedContact,
    setIsEdit,
  ])

  return (
    <Container>
      <Helmet>
        <title>{translate('ShowContributor.helmetTitle')}</title>
      </Helmet>

      {isLoading ? (
        <LoadingIndicator>
          <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
        </LoadingIndicator>
      ) : data ? (
        <>
          <TaskListProvider>
            <ListGroupProvider>
              <ListClientsProvider>
                <TeamsProvider>
                  <BadgeProvider>
                    <ContributorTabs />
                  </BadgeProvider>
                </TeamsProvider>
              </ListClientsProvider>
            </ListGroupProvider>
          </TaskListProvider>
        </>
      ) : (
        <Result
          status="error"
          title={translate('ShowContributor.resultTitle')}
          subTitle={translate('ShowContributor.resultSubTitle')}
          extra={[
            <Button
              type="primary"
              onClick={() => window.location.reload()}
              key="console"
            >
              {translate('ShowContributor.buttonText')}
            </Button>,
          ]}
        />
      )}
    </Container>
  )
}

export default EditContributor
