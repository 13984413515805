/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import { Button, Space, Table } from 'antd'
import InputMask from 'antd-mask-input'
import React, { useState } from 'react'
import { format } from 'date-fns'
import { Container } from './styles'
import { TaskTypeProps } from '~/hooks/Tasks/TaskType/useTaskType'
import { SearchOutlined } from '@ant-design/icons'
import { useDashboardTasks } from '~/hooks/Dashboard/useDashboardTasks'

interface Task {
  _id: string
  date: string
  taskType: {
    title: string
  }
}

interface TaskType {
  _id: string
  title: string
}

interface Props {
  taskType: TaskTypeProps[] | undefined
  loading: boolean
}

const TasksTypesTable: React.FC<Props> = ({ taskType, loading }) => {
  const { data } = useDashboardTasks()
  const getTaskCountByDateAndType = () => {
    const taskCountByDateAndType: {
      [date: string]: { [title: string]: number }
    } = {}
    const totalByTaskType: { [title: string]: number } = {}

    if (data?.filteredTasks && taskType) {
      data?.filteredTasks.forEach((task: Task) => {
        const {
          date,
          taskType: { title },
        } = task
        const formattedDate = format(new Date(date), 'dd/MM/yyyy')
        if (!taskCountByDateAndType[formattedDate]) {
          taskCountByDateAndType[formattedDate] = {}
        }
        taskCountByDateAndType[formattedDate][title] =
          (taskCountByDateAndType[formattedDate][title] || 0) + 1

        // Calcula o total por tipo de tarefa
        totalByTaskType[title] = (totalByTaskType[title] || 0) + 1
      })
    }

    return { taskCountByDateAndType, totalByTaskType }
  }

  const { taskCountByDateAndType } = getTaskCountByDateAndType()

  const columns = taskType
    ? taskType.map((taskType: TaskType) => ({
        title: taskType.title,
        dataIndex: taskType.title,
        key: taskType.title,
        width: 150,
        render: (text: number | undefined) => text || 0, // Renderiza 0 se o valor for undefined
        sorter: (a: any, b: any) => a[taskType.title] - b[taskType.title], // Ordenação por número total de tarefas
      }))
    : []

  const dataSource = Object.keys(taskCountByDateAndType).map((date: string) => {
    const dataRow: { [key: string]: number | string } = { Data: date }
    taskType?.forEach((taskType: TaskType) => {
      dataRow[taskType.title] =
        taskCountByDateAndType[date][taskType.title] || 0
    })

    // Adiciona o total por dia à linha de dados
    dataRow['Total de tarefas do Dia'] = Object.values(dataRow).reduce(
      (acc: number, curr: any) => (typeof curr === 'number' ? acc + curr : acc),
      0,
    )

    return dataRow
  })

  const [, setSearchText] = useState<string>('')
  const [, setSearchedColumn] = useState<string>('')

  const getColumnSearchProps = (
    dataIndex: keyof {
      [key: string]: string | number
    },
  ) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        <InputMask
          mask={'00/00/0000'}
          placeholder={`Buscar data`}
          value={selectedKeys[0]}
          onChange={(e: any) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Resetar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (
      value: any,
      record: {
        [key: string]: string | number
      },
    ) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  })

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: () => void,
    dataIndex: keyof {
      [key: string]: string | number
    },
  ) => {
    confirm()
    setSearchText(selectedKeys[0] as string)
    setSearchedColumn(dataIndex as string)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }

  return (
    <Container>
      <Table
        dataSource={dataSource}
        loading={loading}
        columns={[
          {
            title: 'Data',
            dataIndex: 'Data',
            key: 'Data',
            width: 150,
            fixed: 'left',
            defaultSortOrder: 'descend',
            sorter: (a: any, b: any) => {
              const aDateParts = a.Data.split('/')
              const bDateParts = b.Data.split('/')
              const formattedADate = new Date(
                `${aDateParts[2]}-${aDateParts[1]}-${aDateParts[0]}`,
              )
              const formattedBDate = new Date(
                `${bDateParts[2]}-${bDateParts[1]}-${bDateParts[0]}`,
              )
              const timestampA = formattedADate.getTime()
              const timestampB = formattedBDate.getTime()

              return timestampA - timestampB
            },
            ...getColumnSearchProps('Data'),
          },
          ...columns,
          {
            title: 'Total de tarefas do Dia',
            dataIndex: 'Total de tarefas do Dia',
            key: 'Total de tarefas do Dia',
            fixed: 'right',
            width: 150,
            sorter: (a: any, b: any) =>
              a['Total de tarefas do Dia'] - b['Total de tarefas do Dia'],
          },
        ]}
        pagination={false}
        scroll={{ x: 1000, y: 500 }}
        bordered
        className="custom-last-row"
      />
    </Container>
  )
}

export default TasksTypesTable
