/* eslint-disable camelcase */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { ColumnsType } from 'antd/lib/table'
import {
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Button,
  Avatar,
  Typography,
} from 'antd'
import {
  FileUnknownOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons'

import Text from 'antd/lib/typography/Text'
import { Link } from 'react-router-dom'

import { useTeams } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'
import { stringToHslColor } from '~/utils/stringToHslColor'
import { createLetterAvatar } from '~/utils/letter'

interface contribuitorsTeams {
  collaborator: {
    id: string
    contact: {
      id: string
      name: string
    }
  }
}

interface Managers {
  id: string
  contact: {
    id: string
    name: string
  }
}

interface TeamsProps {
  title: string
  id: string
  created_at: Date
  manager: Managers
  description: string
  contributorsTeams: contribuitorsTeams[]
}

const TeamsTaskTable: React.FC = () => {
  const { teams, isFetching, refetch } = useTeams()
  const { setTeamData } = useTaskCreate()

  async function handleTeamTask(
    id: string,
    name: string,
    amount_to_receive: number,
  ) {
    await setTeamData({ id, name, amount_to_receive })

    refetch()
  }

  const columns: ColumnsType<TeamsProps> = [
    {
      title: 'Ações',
      align: 'center',
      key: 'remove',
      render: (record) => (
        <Popconfirm
          title="Deseja mesmo adicionar a equipe?"
          onConfirm={() =>
            handleTeamTask(record.id, record.title, record.amount_to_receive)
          }
        >
          <Tooltip title="Adicionar equipe">
            <Button size="small" shape="circle" icon={<PlusOutlined />} />
          </Tooltip>
        </Popconfirm>
      ),
    },
    {
      title: '',
      align: 'center',
      key: 'avatar',
      render: (record) => (
        <Avatar
          style={{
            background: stringToHslColor(`${record && record.title}`),
          }}
        >
          {createLetterAvatar(`${record && record.title}}`)}
        </Avatar>
      ),
    },
    {
      title: translate('TeamsTable.titleColumn'),
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name: string, record) => (
        <Link to={`/equipes/visualizar/${record?.id}`}>{name}</Link>
      ),
    },
    {
      title: translate('TeamsTable.responsibleColumn'),
      dataIndex: ['manager', 'contact', 'name'],
      key: 'manager',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (manager: string) => (
        <>
          {manager || (
            <Text type="secondary">
              <Space size="small">
                <FileUnknownOutlined />
                N/A
              </Space>
            </Text>
          )}
        </>
      ),
    },
    {
      title: translate('TeamsTable.teamUserColumn'),
      key: 'team_user',
      render: (record) => (
        <>
          {record.contributorsTeams.length === 1 ? (
            <Text>
              {record.contributorsTeams.length}{' '}
              {translate('TeamsTable.contributorText')}
            </Text>
          ) : (
            <Text>
              {record.contributorsTeams.length}{' '}
              {translate('TeamsTable.contributorsText')}
            </Text>
          )}
        </>
      ),
    },
  ]

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography.Title level={5}>Listagem de Equipes</Typography.Title>
      </div>

      <Table
        rowKey={(record) => record.id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isFetching,
          size: 'large',
        }}
        columns={columns}
        dataSource={teams}
        scroll={{ x: 1000 }}
        size="small"
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </>
  )
}

export default TeamsTaskTable
