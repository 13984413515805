export default {
  pt: {
    HelpDesk: {
      cardTitle: 'Tickets',
      totalText: 'Total de',
      breakReasonText: 'ticket',
      breakReasonsText: 'tickets',
      shareLink: 'Compartilhar',
      shareText:
        'Compartilhar link com cliente para a criação de um novo ticket.',
      buttonText: 'Novo ticket',
      linkText: 'Compartilhar Link - Helpdesk',
      clientText:
        'Escolha para qual cliente será criado o link para o HelpDesk.',
      buttonLink: 'Gerar Link',
    },
  },
  en: {
    HelpDesk: {
      cardTitle: 'Tickets',
      totalText: 'Total of',
      breakReasonText: 'ticket',
      breakReasonsText: 'tickets',
      shareLink: 'Share',
      shareText: 'Share link with customer to create a new ticket.',
      buttonText: 'New ticket',
      linkText: 'Share Link - Helpdesk',
      clientText:
        'Choose for which customer the link to the HelpDesk will be created.',
      buttonLink: 'Generate Link',
    },
  },
  es: {
    HelpDesk: {
      cardTitle: 'Tickets',
      totalText: 'Total de',
      breakReasonText: 'ticket',
      breakReasonsText: 'tickets',
      shareLink: 'Comparta',
      shareText:
        'Comparta el enlace con el cliente para crear un nuevo ticket.',
      buttonText: 'Nuevo ticket',
      linkText: 'Compartir Enlace - Helpdesk',
      clientText: 'Elija para qué cliente se creará el enlace al HelpDesk.',
      buttonLink: 'Generar enlace',
    },
  },
}
