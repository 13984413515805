import { ShareAltOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useTask } from '~/hooks/Tasks/useTask'

// import { Container } from './styles';

const LinkTask: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { task } = useTask()

  const { selectedCompany } = useCompanies()
  const [shortUrl, setShortUrl] = useState('')

  const linkTask = `https://tasks.gstor.com.br/?auth=${selectedCompany},${task?.responsible?.id}`

  const showModal = async () => {
    const accessToken = process.env.REACT_APP_BITLY_ACCESS_TOKEN
    const response = await fetch('https://api-ssl.bitly.com/v4/shorten', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ long_url: linkTask }),
    })
    const data = await response.json()
    setShortUrl(data.link)

    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  console.log(shortUrl)

  return (
    <>
      <Tooltip title="Compartilhar tarefa">
        <Button
          icon={<ShareAltOutlined />}
          ghost
          type="primary"
          onClick={showModal}
          shape="circle"
        />
      </Tooltip>

      <Modal
        title="Acompanhar Tarefa"
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="600px"
      >
        <Col>
          {shortUrl ? (
            <Typography.Link
              href={shortUrl}
              target="_blank"
              style={{ fontSize: 16 }}
              copyable
            >
              {shortUrl}
            </Typography.Link>
          ) : (
            'Nenhum link gerado'
          )}
        </Col>
      </Modal>
    </>
  )
}

export default LinkTask
