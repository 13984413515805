/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { message } from 'antd'

import api from '~/services/api'

import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useLocale } from '~/hooks/locale/useLocale'

import { ShowError } from '~/utils/errors/apiErrors'
import { translate } from '~/utils/locale'

interface TicketsProps {
  _id: string
  title: string
  request_type_id: string
  customer: string
  customer_phone: string
  customer_email: string
  priority: string
  description: string
  status: string
  responsible: {
    id: string
    type: string
  }
  date: string
}

interface ClientProps {
  id: string
  name: string
  email: string
  phone: string
  companyName: string
}

interface FilterOptionsProps {
  title?: string
  request_type_id?: string
  customer?: string
  responsibleTeams?: string
  responsibleContributors?: string
  priority?: string
  description?: string
}

interface TicketsContextData {
  tickets?: TicketsProps[]
  clientData: ClientProps
  setClientData(value: ClientProps): void
  createTicket(valueForm: object | undefined, keepRegister?: boolean): void
  createTicketCustomer(valueForm: object | undefined): void
  deleteTicket(ticketId?: string): void
  isLoading: boolean
  refetch: () => void
  handleUpdateFilters: (newFilterOptions: any) => void
  handleResetFilters: () => void
  filterOptions: FilterOptionsProps
  isFetching: boolean
  setPageSize(value: any): void
  setNumberPage(value: any): void
  ticketsTotal?: number
}

const TicketsContext = createContext<TicketsContextData>(
  {} as TicketsContextData,
)

export const ListTicketsProvider: React.FC = ({ children }) => {
  const { selectedCompany } = useCompanies()
  const { locale } = useLocale()
  const history = useHistory()

  const [pageSize, setPageSize] = useState<number>(10)
  const [numberPage, setNumberPage] = useState<number>(1)

  const [ticketsTotal, setTicketsTotal] = useState<number>()

  const [clientData, setClientData] = useState<ClientProps>({} as ClientProps)

  const [filterOptions, setFilterOptions] = useState<FilterOptionsProps>(() => {
    const filter = localStorage.getItem('@Gstor:ticketsFilterOptions')

    if (filter) {
      return JSON.parse(filter)
    }

    return {} as FilterOptionsProps
  })

  const {
    data: tickets,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    [
      `Tickets${selectedCompany}`,
      selectedCompany,
      filterOptions,
      pageSize,
      numberPage,
    ],

    async () => {
      try {
        if (!selectedCompany) {
          return undefined
        }

        const paginationLimit = numberPage * pageSize
        const paginationOffset = paginationLimit - pageSize

        const response = await api.get(`/company/${selectedCompany}/tickets`, {
          params: {
            offset: paginationOffset,
            limit: paginationLimit,
            responsibleContributors: filterOptions?.responsibleContributors,
            responsibleTeams: filterOptions?.responsibleTeams,
            customer: filterOptions?.customer,
            request_type_id: filterOptions?.request_type_id,
            priority: filterOptions?.priority,
            description: filterOptions?.description,
            title: filterOptions?.title,
          },
        })

        const { data } = response

        setTicketsTotal(data.total)

        return data.results
      } catch (err: any) {
        ShowError(
          err.message,
          translate('useListTickets.getErrorMessage'),
          locale,
        )
      }
    },
  )

  async function createTicket(valueForm: object, keepRegister?: boolean) {
    try {
      await api.post(`/company/${selectedCompany}/ticket`, valueForm)

      refetch()
      message.success(translate('useListTickets.createSuccessMessage'))

      if (!keepRegister) {
        history.push('helpdesk')
      }
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListTickets.createErrorMessage'),
        locale,
      )
    }
  }

  async function createTicketCustomer(valueForm: object) {
    try {
      await api.post(`/company/${selectedCompany}/ticket/customer`, valueForm)

      refetch()
      message.success(translate('useListTickets.createSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListTickets.createErrorMessage'),
        locale,
      )
    }
  }

  async function deleteTicket(ticketId: string) {
    try {
      await api.delete(`/company/${selectedCompany}/ticket/${ticketId}`)

      refetch()
      message.success(translate('useListTickets.deleteSuccessMessage'))
    } catch (err: any) {
      ShowError(
        err.message,
        translate('useListTickets.deleteErrorMessage'),
        locale,
      )
    }
  }

  const handleUpdateFilters = useCallback((newFilterOptions) => {
    const newFilters = {
      responsibleContributors: newFilterOptions?.responsibleContributors,
      responsibleTeams: newFilterOptions?.responsibleTeams,
      customer: newFilterOptions?.customer,
      request_type_id: newFilterOptions?.request_type_id,
      priority: newFilterOptions?.priority,
      description: newFilterOptions?.description,
      title: newFilterOptions?.title,
    }
    setFilterOptions(newFilters)
    localStorage.setItem(
      '@Gstor:ticketsFilterOptions',
      JSON.stringify(newFilters),
    )
  }, [])

  const handleResetFilters = useCallback(() => {
    localStorage.removeItem('@Gstor:ticketsFilterOptions')
    setFilterOptions({})
  }, [])

  useEffect(() => {
    refetch()
  }, [selectedCompany, refetch])

  return (
    <TicketsContext.Provider
      value={{
        tickets,
        createTicket,
        createTicketCustomer,
        deleteTicket,
        isLoading,
        refetch,
        handleUpdateFilters,
        handleResetFilters,
        filterOptions,
        isFetching,
        setPageSize,
        setNumberPage,
        ticketsTotal,
        clientData,
        setClientData,
      }}
    >
      {children}
    </TicketsContext.Provider>
  )
}

export function useListTickets(): TicketsContextData {
  const context = useContext(TicketsContext)

  if (!context) {
    throw new Error('useAuth must be used within an AutoProvider')
  }

  return context
}
