import styled from 'styled-components'

interface CardsProps {
  backgroundColor: string
  iconBackgroundColor: string
  iconColor: string
  titleColor: string
}

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
`

export const Cards = styled.div<CardsProps>`
  background-color: ${(p) => p.backgroundColor};

  padding: 0.8rem;
  border-radius: 0.6rem;

  .header {
    display: flex;
    align-items: center;

    p {
      font-size: 15px;
      color: ${(p) => p.titleColor};
    }
  }

  .icon {
    width: 45px;
    height: 45px;
    background-color: black;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${(p) => p.iconBackgroundColor};

    svg {
      font-size: 18px;
      color: ${(p) => p.iconColor};
    }
  }

  .content {
    margin-left: 1rem;
  }

  h4 {
    margin: 0;
    color: ${(p) => p.titleColor};
    //padding-top: 0.3rem;
  }
`
