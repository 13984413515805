/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Card, Modal, Radio, Result } from 'antd'
import { PieChart, Cell, Pie, Tooltip, ResponsiveContainer } from 'recharts'
import { useDashboardTasks } from '~/hooks/Dashboard/useDashboardTasks'
import ModalTasksTable from '~/components/DataManipulation/Dashboard/Tasks/Tables/ModalTable'
import { LoadingIndicator } from '../../styles'
import { LoadingOutlined } from '@ant-design/icons'

interface TasksByContributorAndValuePieChartProps {
  type: string
}

const TasksByContributorAndValuePieChart: React.FC<
  TasksByContributorAndValuePieChartProps
> = ({ type }) => {
  const { data, isLoading } = useDashboardTasks()
  const [tasksData, setTasksData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [sortBy, setSortBy] = useState('totalTarefas')

  if (isLoading || !data) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const sortedData = [...data?.ContributorTasks].sort(
    (a: any, b: any) => b[sortBy] - a[sortBy],
  )

  const stringToColor = (str: string) => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += ('00' + value.toString(16)).substr(-2)
    }
    return color
  }

  const handleClick = (data: any) => {
    setTasksData(data.tasks)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Card
      title={
        <div style={{ textAlign: 'center' }}>
          Número de Tarefas e Valor médio por Colaborador
        </div>
      }
      style={{ width: '100%', height: '100%', maxHeight: '500px' }}
    >
      {sortedData.length === 0 ? (
        <Result title="Não existem tarefas nas datas selecionadas no filtro" />
      ) : (
        <>
          <Radio.Group
            onChange={(e) => setSortBy(e.target.value)}
            value={sortBy}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '20px',
            }}
          >
            <Radio value={'totalTarefas'}>Total de Tarefas</Radio>
            <Radio value={'totalMedia'}>Valor Médio</Radio>
            <Radio value={'valorTotal'}>Valor Total</Radio>
          </Radio.Group>

          <ResponsiveContainer
            width="100%"
            height={type === 'dash' ? 300 : 400}
          >
            <PieChart>
              <Pie
                dataKey={sortBy}
                isAnimationActive={true}
                animationDuration={750}
                animationEasing="linear"
                data={sortedData}
                cx="50%"
                cy="50%"
                outerRadius="80%"
                fill="#8884d8"
                label={({ [sortBy]: value }) =>
                  sortBy === 'totalTarefas'
                    ? value
                    : `${value.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}`
                }
                onClick={handleClick}
              >
                {sortedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={stringToColor(entry.nome)}
                    style={{ cursor: 'pointer' }}
                  />
                ))}
              </Pie>
              <Tooltip
                formatter={(value, name, props) => {
                  const { payload } = props
                  const formattedValue =
                    sortBy === 'totalTarefas'
                      ? value
                      : value.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                  return [formattedValue, payload.nome]
                }}
              />
            </PieChart>
          </ResponsiveContainer>

          <Modal
            width="80vw"
            open={isModalOpen}
            onCancel={closeModal}
            footer={null}
          >
            <ModalTasksTable tasks={tasksData} />
          </Modal>
        </>
      )}
    </Card>
  )
}

export default TasksByContributorAndValuePieChart
