/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-underscore-dangle */
import React from 'react'

import {
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Card,
  Space,
  Button,
} from 'antd'

import PhoneInputWithCountrySelect from 'react-phone-number-input'
import pt_BR from 'react-phone-number-input/locale/pt-BR.json'
import dayjs from 'dayjs'

import { translate } from '~/utils/locale'
import { useTicketType } from '~/hooks/HelpDesk/useTicketType'

import { useTicket } from '~/hooks/HelpDesk/useTickets'
import { FormButtonContainer } from '~/pages/Clients/NewClients/styles'
import { ContainerButton } from './styles'

interface TicketProps {
  title: string
  request_type_id: string
  customer: string
  customer_phone: string
  customer_email: string
  priority: string
  description: string
  id: string
  date: string
  status: string
}

const UpdateTicket: React.FC = () => {
  const { ticket, updateTicket, refetch, setIsEdit } = useTicket()

  const { ticketType, isLoading } = useTicketType()

  const initialData = {
    title: ticket?.title,
    request_type_id: ticket?.ticketType?._id,
    customer: ticket?.customer,
    customer_email: ticket?.customer_email,
    customer_phone: ticket?.customer_phone,
    id: ticket?.responsible?.id,
    priority: ticket?.priority,
    date: dayjs(ticket?.date),
    description: ticket?.description,
    status: ticket?.status,
  }

  async function updateTickets(dataForm: TicketProps) {
    try {
      if (dataForm.customer_phone) {
        dataForm.customer_phone = dataForm.customer_phone.replace(/[^0-9]/g, '')
      }

      const ticketsData = {
        title: dataForm.title,
        request_type_id: dataForm.request_type_id,
        customer: dataForm.customer,
        customer_email: dataForm.customer_email,
        customer_phone: dataForm.customer_phone,
        priority: dataForm.priority,
        description: dataForm.description,
        date: dataForm.date,
        status: dataForm.status,
      }

      await updateTicket(ticketsData)
      setIsEdit(false)

      refetch()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Card
        bordered={false}
        title={translate('HelpDeskTabs.edit')}
        style={{
          display: 'inline-block',
          width: '100%',
        }}
      >
        <Form
          layout="vertical"
          id="editTicket"
          initialValues={initialData}
          onFinish={updateTickets}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.titleLabel')}
                name="title"
                rules={[
                  {
                    required: true,
                    message: translate('HelpDeskCreate.titleRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('HelpDeskCreate.titlePlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.solicitationLabel')}
                name="request_type_id"
                rules={[
                  {
                    required: true,
                    message: translate('HelpDeskCreate.solicitationRule'),
                  },
                ]}
              >
                <Select
                  placeholder={translate(
                    'HelpDeskCreate.solicitationPlaceholder',
                  )}
                  loading={isLoading}
                >
                  {ticketType?.map((t) => (
                    <Select.Option value={t._id} key={t._id}>
                      {t.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.clientLabel')}
                name="customer"
                rules={[
                  {
                    required: true,
                    message: translate('HelpDeskCreate.clientRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('HelpDeskCreate.clientPlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.emailClientLabel')}
                name="customer_email"
                rules={[
                  {
                    required: true,
                    message: translate('HelpDeskCreate.emailClientRule'),
                  },
                ]}
              >
                <Input
                  placeholder={translate(
                    'HelpDeskCreate.emailClientPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.phoneClientLabel')}
                name="customer_phone"
              >
                <PhoneInputWithCountrySelect
                  placeholder={translate(
                    'HelpDeskCreate.phoneClientPlaceholder',
                  )}
                  labels={pt_BR}
                  defaultCountry="BR"
                  international
                  onChange={() => {}}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Status" name="status">
                <Select placeholder="Selecione o status">
                  <Select.Option value="new">Novo</Select.Option>
                  <Select.Option value="working">Em Atendimento</Select.Option>
                  <Select.Option value="completed">Concluído</Select.Option>
                  <Select.Option value="canceled">Cancelado</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.priorityLabel')}
                name="priority"
                rules={[
                  {
                    required: true,
                    message: translate('HelpDeskCreate.priorityRule'),
                  },
                ]}
              >
                <Select
                  placeholder={translate(
                    'TasksCreateDetails.priorityPlaceholder',
                  )}
                >
                  <Select.Option value="high">
                    {translate('TasksCreateDetails.priorityHigh')}
                  </Select.Option>
                  <Select.Option value="medium">
                    {translate('TasksCreateDetails.priorityMedium')}
                  </Select.Option>
                  <Select.Option value="low">
                    {translate('TasksCreateDetails.priorityLow')}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={translate('HelpDeskCreate.dateLabel')}
                name="date"
                rules={[
                  {
                    required: true,
                    message: translate('HelpDeskCreate.dateRules'),
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY HH:mm"
                  style={{ width: '100%' }}
                  placeholder={translate('HelpDeskCreate.datePlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={translate('HelpDeskCreate.descriptionLabel')}
            name="description"
          >
            <Input.TextArea
              placeholder={translate('HelpDeskCreate.descriptionPlaceholder')}
            />
          </Form.Item>

          <FormButtonContainer>
            <Space size="large" style={{ alignItems: 'baseline' }}>
              <ContainerButton>
                <Button
                  htmlType="submit"
                  type="primary"
                  form="editTicket"
                  id="btn_create_ticket"
                >
                  {translate('TasksUpdate.saveButton')}
                </Button>
              </ContainerButton>
              <Button onClick={() => setIsEdit(false)}>
                {translate('TasksUpdate.cancelButton')}
              </Button>
            </Space>
          </FormButtonContainer>
        </Form>
      </Card>
    </>
  )
}

export default UpdateTicket
