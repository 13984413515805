/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Card, Modal, Result } from 'antd'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
  LabelList,
  LabelProps,
  TooltipProps,
} from 'recharts'
import { useDashboardTasks } from '~/hooks/Dashboard/useDashboardTasks'
import ModalTasksTable from '~/components/DataManipulation/Dashboard/Tasks/Tables/ModalTable'

const TasksValueLineChart: React.FC = () => {
  const { data } = useDashboardTasks()
  const [tasksData, setTasksData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const renderCustomizedLabel = (props: LabelProps) => {
    const { x, y, value } = props

    if (value === undefined) {
      return null
    }

    const formattedValue = value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

    return (
      <text x={x} y={y} fill="#3C8ACB" textAnchor="middle" dy={-10}>
        {formattedValue}
      </text>
    )
  }

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<number, string>) => {
    if (active && payload && payload.length && payload[0].value !== undefined) {
      const formattedValue = payload[0].value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })

      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '3px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
          }}
        >
          <p className="label" style={{ margin: 0 }}>
            {label}
          </p>
          <p
            className="label"
            style={{ color: '#3C8ACB', margin: 0 }}
          >{`Valor Total das Tarefas : ${formattedValue}`}</p>
        </div>
      )
    }

    return null
  }

  const handleClick = (data: any) => {
    const tasks = data.activePayload[0].payload.tasks
    setTasksData(tasks)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Card
      title={
        <div style={{ textAlign: 'center' }}>
          Valor Total das Tarefas nos Últimos dias
        </div>
      }
      style={{ width: '100%', height: '100%', maxHeight: '500px' }}
    >
      {data?.TasksValueLineChart.length === 0 ? (
        <Result title="Não existem tarefas nas datas selecionadas no filtro" />
      ) : (
        <>
          <ResponsiveContainer width="100%" height={400}>
            <AreaChart data={data?.TasksValueLineChart} onClick={handleClick}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                tickFormatter={(value) =>
                  value.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                }
              />
              <Tooltip content={<CustomTooltip />} />
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#64C77C" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#64C77C" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="totalTaskValue"
                stroke="#3C8ACB"
                fill="url(#colorUv)"
                style={{ cursor: 'pointer' }}
              >
                <LabelList
                  dataKey="totalTaskValue"
                  position="top"
                  content={renderCustomizedLabel}
                />
              </Area>
            </AreaChart>
          </ResponsiveContainer>

          <Modal
            width="80vw"
            open={isModalOpen}
            onCancel={closeModal}
            footer={null}
          >
            <ModalTasksTable tasks={tasksData} />
          </Modal>
        </>
      )}
    </Card>
  )
}

export default TasksValueLineChart
