/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import {
  DeleteFilled,
  LoadingOutlined,
  ProfileOutlined,
} from '@ant-design/icons'
import { Button, Popconfirm, Space, Tag, Tooltip, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Text from 'antd/lib/typography/Text'
import { format } from 'date-fns'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { useListContributors } from '~/hooks/Contributors/useListContributors'
import { useTicket } from '~/hooks/HelpDesk/useTickets'
import { useTaskType } from '~/hooks/Tasks/TaskType/useTaskType'

import { useTeams } from '~/hooks/Teams/useListTeams'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface EquipmentsTasks {
  id: string
}

interface TaskListProps {
  checkin_type: string
  createdAt: Date
  date: string
  duration_time: string
  external_code: string
  is_enabled: string
  priority: string
  _id: string
  hours: string
  currentDate: string
  internal_code: string
  current_status: string
  customer_id: string
  task_type_id: string
  responsible: {
    id: string
    type: string
  }
  address: {
    city: string
    id: string
    location: {
      x: number
      y: number
    }
    neighborhood: string
    number: number
    state: string
    street: string
    zip_code: string
  }
  equipments: EquipmentsTasks[]
}

const TableTasksTickets: React.FC = () => {
  const { ticket, isFetching, deleteTaskTicket } = useTicket()
  const { data } = useListContributors()
  const { teams } = useTeams()
  const { taskType } = useTaskType()

  const teste = ticket?.tasks.filter((a) => a._id)

  console.log(teste)

  const taskFormatted = ticket?.tasks?.map((task: any) => {
    const dataFormat = {
      ...task,
      initialTime: format(
        new Date(task.date),
        `dd/MM/yyyy '${translate('TasksTable.dateString')}' HH:mm'h'`,
      ),
    }

    return dataFormat
  })

  const columns: ColumnsType<TaskListProps> = [
    {
      title: translate('TasksTable.internalCodeColumn'),
      dataIndex: 'internal_code',
      key: 'internal_code',
      render: (name: string, record) => (
        <Link to={`/tarefas/visualizar/${record._id}`}>{name}</Link>
      ),
    },
    {
      title: translate('TasksTable.responsibleColumn'),
      key: 'responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <>
          {record.responsible?.type === 'collaborator' ? (
            <>
              {data?.map((d) => (
                <div key={d.id}>
                  {d.id === record.responsible.id ? (
                    <Text>{d.contact.name}</Text>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              {teams?.map((t) => (
                <div key={t.id}>
                  {t.id === record.responsible?.id ? (
                    <Text>{t.title}</Text>
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </>
          )}
        </>
      ),
    },
    {
      title: translate('TasksTable.initialTimeColumn'),
      dataIndex: 'initialTime',
      key: 'date',
      sorter: (a: TaskListProps, b: TaskListProps) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: translate('TasksTable.taskTypeColumn'),
      key: 'taskType',
      render: (record) => (
        <>
          {taskType?.map((task) => (
            <div key={task._id}>
              {task._id === record.task_type_id ? (
                <Text>{task.title}</Text>
              ) : (
                ''
              )}
            </div>
          ))}
        </>
      ),
    },
    {
      title: translate('TasksTable.statusColumn'),
      key: 'status',
      render: (record) => (
        <>
          {record.current_status === 'received' ? (
            <Tag color="green">{translate('TasksTable.statusReceived')}</Tag>
          ) : (
            ''
          )}
          {record.current_status === 'viewed' ? (
            <Tag color="green">{translate('TasksTable.statusViewed')}</Tag>
          ) : (
            ''
          )}
          {record.current_status === 'en-route' ? (
            <Tag color="green">{translate('TasksTable.statusEnRoute')}</Tag>
          ) : (
            ''
          )}
          {record.current_status === 'working' ? (
            <Tag color="green">{translate('TasksTable.statusWorking')}</Tag>
          ) : (
            ''
          )}
          {record.current_status === 'break' ? (
            <Tag color="red">{translate('TasksTable.statusBreak')}</Tag>
          ) : (
            ''
          )}
          {record.current_status === 'resume-working' ? (
            <Tag color="green">
              {translate('TasksTable.statusResumeWorking')}
            </Tag>
          ) : (
            ''
          )}
          {record.current_status === 'canceled' ? (
            <Tag color="gray">{translate('TasksTable.statusCanceled')}</Tag>
          ) : (
            ''
          )}
          {record.current_status === 'finished' ? (
            <Tag color="geekblue">{translate('TasksTable.statusFinished')}</Tag>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: translate('TasksTable.equipmentColumn'),
      key: 'taskType',
      render: (record) => (
        <>
          {record.equipments.length === 1 ? (
            <Tag>1 {translate('TasksTable.tagEquipment')}</Tag>
          ) : (
            <Tag>
              {record.equipments.length} {translate('TasksTable.tagEquipments')}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: translate('TasksTable.actionsColumn'),
      key: 'remove',
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <Tooltip title={translate('TasksTable.viewTooltip')}>
            <Link to={`/tarefas/visualizar/${record._id}`}>
              <Button
                type="primary"
                ghost
                shape="circle"
                icon={<ProfileOutlined />}
              />
            </Link>
          </Tooltip>
          <Popconfirm
            title={translate('TasksTable.unlinkPopconfirm')}
            onConfirm={() => deleteTaskTicket(record._id)}
          >
            <Tooltip title={translate('TasksTable.unlinkTask')}>
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Table
      rowKey={(record) => record._id}
      loading={{
        indicator: <LoadingOutlined />,
        spinning: isFetching,
        size: 'large',
      }}
      columns={columns}
      dataSource={taskFormatted}
      scroll={{ x: 1000 }}
    />
  )
}

export default TableTasksTickets
