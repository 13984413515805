export default {
  pt: {
    MaintenanceSystemTab: {
      Activities: 'Descrição',
      AddActivity: 'Adicionar Atividade',
      Title: 'Atividade',
      Responsibles: 'Executor/Fornecedor',
      Competence: 'Equipe/Competência',
      Periodicity: 'Periodicidade',
      AddActivities: 'Adicionar Atividades',
      NewActivity: 'Nova Atividade',
      ConfirmDeleteActivity: 'Deseja mesmo deletar a atividade?',
      ActivityTab: 'Atividades',
      TaskModalTitle: 'Visualizar Tarefas da Atividade',

      Days: 'dias',
      Day: 'dia',
      Weeks: 'semanas',
      Week: 'semana',
      Months: 'mês',
      MonthsPlural: 'meses',
      Year: 'ano',
      Years: 'anos',

      Text: 'A cada',

      ViewActivity: 'Visualizar Atividade',
      Details: 'Detalhes',
      ActivityTitle: 'Título da Atividade',
      DataSource: 'Fonte de Dados',
      Components: 'Componentes',
      ViewTasks: 'Ver tarefas',
    },
  },
  en: {
    MaintenanceSystemTab: {
      Activities: 'Description',
      AddActivity: 'Add Activity',
      Title: 'Activity',
      Responsibles: 'Executor/Supplier',
      Competence: 'Team/Competence',
      Periodicity: 'Periodicity',
      AddActivities: 'Add Activities',
      NewActivity: 'New Activity',
      ConfirmDeleteActivity: 'Do you really want to delete the activity?',
      ActivityTab: 'Activities',
      TaskModalTitle: 'View Activity Tasks',

      Days: 'days',
      Day: 'day',
      Weeks: 'weeks',
      Week: 'week',
      Months: 'month',
      MonthsPlural: 'months',
      Year: 'year',
      Years: 'years',

      Text: 'Every',

      ViewActivity: 'View Activity',
      Details: 'Details',
      ActivityTitle: 'Activity Title',
      DataSource: 'Data Source',
      Components: 'Components',
      ViewTasks: 'View tasks',
    },
  },
  es: {
    MaintenanceSystemTab: {
      Activities: 'Descripción',
      AddActivity: 'Añadir Actividad',
      Title: 'Actividad',
      Responsibles: 'Ejecutor/Proveedor',
      Competence: 'Equipo/Competencia',
      Periodicity: 'Periodicidad',
      AddActivities: 'Añadir Actividades',
      NewActivity: 'Nueva Actividad',
      ConfirmDeleteActivity: '¿Seguro que desea eliminar la actividad?',
      ActivityTab: 'Actividades',
      TaskModalTitle: 'Ver Tareas de Actividad',

      Days: 'días',
      Day: 'día',
      Weeks: 'semanas',
      Week: 'semana',
      Months: 'mes',
      MonthsPlural: 'meses',
      Year: 'año',
      Years: 'años',

      Text: 'Cada',

      ViewActivity: 'Ver Actividad',
      Details: 'Detalles',
      ActivityTitle: 'Título de la Actividad',
      DataSource: 'Fuente de Datos',
      Components: 'Componentes',
      ViewTasks: 'Ver tareas',
    },
  },
}
