/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Card, Modal, Result } from 'antd'
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from 'recharts'
import { useDashboardTasks } from '~/hooks/Dashboard/useDashboardTasks'
import ModalTasksTable from '~/components/DataManipulation/Dashboard/Tasks/Tables/ModalTable'

interface TasksByValuePieChartProps {
  type: string
}

const TasksByValuePieChart: React.FC<TasksByValuePieChartProps> = ({
  type,
}) => {
  const { data } = useDashboardTasks()
  const [tasksData, setTasksData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const totalValue = data?.TasksByValuePieChart.reduce(
    (total, task) => total + task.totalValue,
    0,
  )

  // const stringToColor = (str: string) => {
  //   let hash = 0
  //   for (let i = 0; i < str.length; i++) {
  //     hash = str.charCodeAt(i) + ((hash << 5) - hash)
  //   }
  //   let color = '#'
  //   for (let i = 0; i < 3; i++) {
  //     const value = (hash >> (i * 8)) & 0xff
  //     color += ('00' + value?.toString(16)).substr(-2)
  //   }
  //   return color
  // }

  const handleClick = (data: any) => {
    setTasksData(data.tasks)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Card
      title={
        <div style={{ textAlign: 'center' }}>
          Valor total por Tipos de Serviço
        </div>
      }
      style={{ width: '100%', height: '100%', maxHeight: '500px' }}
    >
      {data?.TasksByValuePieChart.length === 0 ? (
        <Result title="Não existem tarefas nas datas selecionadas no filtro" />
      ) : (
        <>
          <ResponsiveContainer
            width="100%"
            height={type === 'dash' ? 180 : 400}
          >
            <PieChart>
              <Pie
                dataKey="totalValue"
                isAnimationActive={true}
                animationDuration={750}
                animationEasing="linear"
                data={data?.TasksByValuePieChart}
                cx="50%"
                cy="50%"
                outerRadius="80%"
                innerRadius="50%"
                fill="#332da0"
                label={({ totalValue }) =>
                  `${totalValue?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}`
                }
                onClick={handleClick}
              >
                {data?.TasksByValuePieChart.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill="#4741c2"
                    style={{ cursor: 'pointer' }}
                  />
                ))}
              </Pie>
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
                fill="rgba(0, 0, 0, 0.85)"
                fontSize="1.5em"
                fontWeight="bold"
              >
                {totalValue?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </text>
              <Tooltip
                formatter={(value) =>
                  value?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                }
              />
            </PieChart>
          </ResponsiveContainer>

          <Modal
            width="80vw"
            open={isModalOpen}
            onCancel={closeModal}
            footer={null}
          >
            <ModalTasksTable tasks={tasksData} />
          </Modal>
        </>
      )}
    </Card>
  )
}

export default TasksByValuePieChart
