/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Col,
  Row,
  Form,
  Select,
  DatePicker,
  Input,
  Space,
  Card,
  Button,
  Checkbox,
  Divider,
} from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-components'

import React, { useState } from 'react'

import { useCategory } from '~/hooks/Equipments/Category/CategoryHooks'
import { useListEquipments } from '~/hooks/Equipments/ListEquipmentsHooks'

import { Container, ContForm } from './styles'
import CreateEquipmentsCategory from '../Category/Create'
import { translate } from '~/utils/locale'

const CreateEquipments: React.FC = () => {
  const { category } = useCategory()
  const { createEquipment } = useListEquipments()

  const [keepRegister, setKeepRegister] = useState(false)

  const [form] = Form.useForm()

  function onCreateEquipments(valueForm: any) {
    try {
      createEquipment(valueForm, keepRegister)
    } catch (err) {
      if (!err) {
        form.resetFields()
      }
    }
  }

  const { Option } = Select

  const dateFormat = 'DD/MM/YYYY'

  return (
    <Container>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={translate('EquipmentsCreate.pageheaderTitle')}
      />
      <Form
        layout="vertical"
        id="newSaveEquipments"
        onFinish={onCreateEquipments}
        form={form}
      >
        <Card
          title={translate('EquipmentsCreate.cardTitle')}
          extra={
            <Space size="small">
              <Checkbox
                onChange={(e) => {
                  setKeepRegister(e.target.checked)
                }}
                checked={keepRegister}
              >
                {translate('EquipmentsCreate.keepRegisterText')}
              </Checkbox>
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                form="newSaveEquipments"
                htmlType="submit"
                id="btn_create_equipments"
              >
                {translate('EquipmentsCreate.saveButton')}
              </Button>
            </Space>
          }
          bordered={false}
          style={{ width: '100%', padding: 30 }}
        >
          <Row>
            <ContForm>
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label={translate('EquipmentsCreate.nameLabel')}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: translate('EquipmentsCreate.nameRule'),
                      },
                    ]}
                  >
                    <Input
                      placeholder={translate(
                        'EquipmentsCreate.namePlaceholder',
                      )}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={translate('EquipmentsCreate.identifierLabel')}
                    name="identifier"
                    rules={[
                      {
                        required: true,
                        message: translate('EquipmentsCreate.identifierRule'),
                      },
                    ]}
                  >
                    <Input
                      placeholder={translate(
                        'EquipmentsCreate.identifierPlaceholder',
                      )}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    label={translate('EquipmentsCreate.expirationDateLabel')}
                    name="expiration_date"
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      format={dateFormat}
                      inputReadOnly
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={translate('EquipmentsCreate.categoryLabel')}
                    name="category_id"
                    rules={[
                      {
                        required: true,
                        message: 'Selecione a categoria',
                      },
                    ]}
                  >
                    <Select
                      placeholder={translate(
                        'EquipmentsCreate.categoryPlaceholder',
                      )}
                      dropdownRender={(menu: any) => (
                        <>
                          {menu}
                          <Divider style={{ margin: '8px 0' }} />
                          <CreateEquipmentsCategory
                            buttonStyle={{ width: '100%' }}
                          />
                        </>
                      )}
                    >
                      {category?.map((categories) => (
                        <Option value={categories.id} key={categories.id}>
                          {categories.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </ContForm>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                label={translate('EquipmentsCreate.descriptionLabel')}
                name="description"
              >
                <Input.TextArea
                  placeholder={translate(
                    'EquipmentsCreate.descriptionPlaceholder',
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Container>
  )
}

export default CreateEquipments
