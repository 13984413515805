/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { Cards, Container } from './styles'

import { Tooltip, Typography } from 'antd'
import { Link } from 'react-router-dom'

interface CardsProps {
  backgroundColor: string
  iconBackgroundColor: string
  iconColor: string
  titleColor: string
  title: string
  value?: number
  id: number
  icon: JSX.Element
  ticket: boolean
}

interface CardProps {
  data: CardsProps[]
}

const CardsTasks: React.FC<CardProps> = ({ data }) => {
  return (
    <Container>
      {data.map((d) => (
        <Tooltip title="Ver tarefas" key={d.id}>
          <Link to="/tarefas">
            <Cards
              key={d.id}
              backgroundColor={d.backgroundColor}
              iconBackgroundColor={d.iconBackgroundColor}
              iconColor={d.iconColor}
              titleColor={d.titleColor}
            >
              <div className="header">
                <div className="icon">{d.icon}</div>
                <div className="content">
                  <p>{d.title}</p>
                  <Typography.Title level={4}>
                    {d.value} {d.ticket ? 'Tickets' : 'Tarefas'}
                  </Typography.Title>
                </div>
              </div>
            </Cards>
          </Link>
        </Tooltip>
      ))}
    </Container>
  )
}

export default CardsTasks
