/* eslint-disable @typescript-eslint/no-explicit-any */

import { Container } from './styles'
import { PageHeader } from '@ant-design/pro-components'
import { translate } from '~/utils/locale'
import {
  Button,
  Card,
  Checkbox,
  Form,
  Progress,
  ProgressProps,
  Space,
  Steps,
  message,
  notification,
} from 'antd'
import { useTaskTour } from '~/hooks/Tour/useTaskTour'
import { PlusCircleOutlined } from '@ant-design/icons'
import DetailsCreateTask from './Details'
import { useTaskCreate } from '~/hooks/Tasks/Create/createTask'
import AddressCreateTask from './Address'
import CreateFinancialTask from './Financial'
import ResponsibleCreateTask from './Responsible'
import { useListTask } from '~/hooks/Tasks/useListTasks'

import React, { useState } from 'react'
import { useTaskAddressFieldsCreate } from '~/hooks/Tasks/Create/Fields/useFieldsTaskAddress'
import dayjs from 'dayjs'

interface TaskProps {
  type: string
  task_type_id: string
  address_id: string
  id: string
  initialDate: Date
  duration_time: Date
  priority: string
  description: string
  customer_id: string
  checkin_type: string
  external_code: string
  zip_code: string
  street: string
  number: string
  complement: string
  neighborhood: string
  city: string
  state: string
  location: { x: number; y: number }
  latitude: any
  longitude: any
  typeResponsible: string
  value: number
  receive: number
  note: string
  forms: Array<string>
  amount_hours: number
  amount_hours_additional: number
  number_km: number
  number_km_additional: number
  amount_hours_receive: number
  amount_hours_additional_receive: number
  number_km_receive: number
  number_km_additional_receive: number
}

interface ValidationError {
  name: (string | number)[]
  errors: string[]
}

const CreateTasksTesting: React.FC = () => {
  const { createTask, createParentCustomerTask, createTeamTask } = useListTask()

  const {
    clientData,
    taskTypeData,
    dateTask,
    contributorData,
    teamData,
    description,
    duration,
    externalCode,
    priority,
    note,
    type,
  } = useTaskCreate()

  const {
    zipcode,
    city,
    state,
    street,
    number,
    neighborhood,
    complement,
    latitude,
    longitude,
  } = useTaskAddressFieldsCreate()

  const { saveTaskRef } = useTaskTour()
  const [keepRegister, setKeepRegister] = useState(false)

  const [current, setCurrent] = useState(0)
  const [form] = Form.useForm()

  const next = () => {
    form
      .validateFields()
      .then(() => {
        setCurrent(current + 1)
      })
      .catch(({ errorFields }: { errorFields: ValidationError[] }) => {
        errorFields.forEach(({ name, errors }) => {
          notification.error({
            message: 'Erro de Validação',
            description: `Campo ${name.join(', ')} não preenchido: ${
              errors[0]
            }`,
            placement: 'topRight',
            duration: 5,
          })
        })
      })
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const steps = [
    {
      title: 'Detalhes da tarefa',
      content: <DetailsCreateTask form={form} />,
    },
    {
      title: 'Endereço',
      content: <AddressCreateTask form={form} />,
    },
    {
      title: 'Responsável',
      content: <ResponsibleCreateTask form={form} />,
    },
    {
      title: 'Informações adicionais de serviço',
      content: <CreateFinancialTask form={form} />,
    },
  ]

  const initialValue = {
    external_code: Math.random().toString(16).substr(2),
    initialDate: dayjs(new Date()),
    priority: 'medium',
  }

  const twoColors: ProgressProps['strokeColor'] = {
    '0%': '#108ee9',
    '100%': '#87d068',
  }

  async function handleForm(valueForm: TaskProps) {
    let responsibleId: string | undefined = ''

    if (form.getFieldError('mapa')[0]) {
      message.error('Teste')
    }

    if (type === 'collaborator') {
      responsibleId = contributorData?.id
    } else {
      responsibleId = teamData?.id
    }

    const dataTeam = {
      title: teamData?.name,
      tasks: [
        {
          task_id: '1232',
          internal_code: externalCode,
          financialReceive: {
            receive: valueForm.receive,
            amount_hours_receive: valueForm.amount_hours_receive
              ? valueForm.amount_hours_receive
              : 0,
            amount_hours_additional_receive:
              valueForm.amount_hours_additional_receive
                ? valueForm.amount_hours_additional_receive
                : 0,
            number_km_receive: valueForm.number_km_receive
              ? valueForm.number_km_receive
              : 0,
            number_km_additional_receive: valueForm.number_km_additional_receive
              ? valueForm.number_km_additional_receive
              : 0,
          },
        },
      ],
    }

    const dataForm = {
      task_type_id: taskTypeData?.id,
      responsible: {
        id: responsibleId,
        type: type === 'any' ? '' : type,
      },
      date: dateTask,
      duration_time: duration,
      priority,
      description,
      checkin_type: valueForm.checkin_type,
      external_code: externalCode,
      value: valueForm.value,
      receive: valueForm.receive,
      note,
      customer_id: clientData?.id,
      address: {
        zip_code: zipcode,
        street,
        number,
        complement,
        neighborhood,
        city,
        state,
        location: {
          x: latitude,
          y: longitude,
        },
      },
      financial: {
        task_value: valueForm.value,
        amount_hours: valueForm.amount_hours,
        amount_hours_additional: valueForm.amount_hours_additional,
        number_km: valueForm.number_km,
        number_km_additional: valueForm.number_km_additional,
      },
      financialReceive: {
        receive: valueForm.receive,
        amount_hours_receive: valueForm.amount_hours_receive,
        amount_hours_additional_receive:
          valueForm.amount_hours_additional_receive,
        number_km_receive: valueForm.number_km_receive,
        number_km_additional_receive: valueForm.number_km_additional_receive,
      },
    }

    createTask(dataForm)

    if (clientData?.parent_cnpj) {
      createParentCustomerTask(dataForm, clientData?.parent_cnpj)
    }

    if (teamData?.id) {
      createTeamTask(dataTeam)
    }
  }

  const onFinishFailed = ({
    errorFields,
  }: {
    errorFields: Array<{ name: (string | number)[]; errors: string[] }>
  }) => {
    errorFields.forEach(({ errors }) => {
      notification.error({
        message: 'Erro de Validação',
        description: `${errors[0]}`,
        placement: 'topRight',
        duration: 5,
      })
    })
  }

  return (
    <Container>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={translate('TasksCreate.pageheaderTitle')}
      />

      <Card
        title={translate('TasksCreate.cardTitle')}
        extra={
          <Space size="small">
            <Checkbox
              onChange={(e) => {
                setKeepRegister(e.target.checked)
              }}
              checked={keepRegister}
            >
              {translate('TasksCreate.keepRegisterText')}
            </Checkbox>

            <div>
              <div>
                {current > 0 && (
                  <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                    Voltar
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button type="primary" onClick={next}>
                    Próximo
                  </Button>
                )}
              </div>
            </div>

            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              form="tasksForm"
              htmlType="submit"
              ref={saveTaskRef}
              disabled={current < 2}
              id={current < 2 ? '' : 'btn_create_task'}
            >
              {translate('TasksCreate.registerButton')}
            </Button>
          </Space>
        }
        bordered={false}
        style={{ width: '100%', padding: 30 }}
      >
        <Progress
          percent={Math.floor((current / (steps.length - 1)) * 100)}
          style={{ margin: '0 0 10px' }}
          strokeColor={twoColors}
          size="small"
        />

        <Form
          layout="vertical"
          id="tasksForm"
          form={form}
          onFinish={handleForm}
          initialValues={initialValue}
          onFinishFailed={onFinishFailed}
        >
          <Steps
            className="site-navigation-steps"
            items={steps}
            current={current}
          ></Steps>
          <div style={{ marginTop: 18 }}>{steps[current].content}</div>
        </Form>

        <div style={{ marginTop: 10 }}>
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Voltar
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={next}>
              Próximo
            </Button>
          )}
        </div>
      </Card>
    </Container>
  )
}

export default CreateTasksTesting
