import styled from 'styled-components'

export const Container = styled.div`
  h3 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  .list {
    display: flex;
    flex-direction: column;
  }

  .list-row {
    display: flex;
    align-items: center;
    gap: 4rem;
    margin-top: 10px;
  }
`
