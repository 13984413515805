/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteFilled, LoadingOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd'
import React from 'react'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { LoadingIndicator } from '~/pages/Contributor/ShowContributor/styles'

import { ColumnsType } from 'antd/lib/table'
import { translate } from '~/utils/locale'
import ViewActivity from './View'
import AddActivityModal from './AddActivity'
import { format } from 'date-fns'

import Text from 'antd/lib/typography/Text'
import TasksActivityMaintenance from './Tasks'

interface ActivityProps {
  _id: string
  title: string
  createdAt: string
  component?: string
  activity?: string
  competence?: string
  periodicity: {
    type: string
    interval: number
    date: Date
  }
  initialDate: Date
  responsible: string
  data_font: string
  tasks: [
    {
      taskId: string
      created: Date
    },
  ]
}

const ActivityTab: React.FC = () => {
  const { isLoading, maintenance, deleteActivity } = useShowMaintenance()

  if (isLoading || !maintenance) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const columns: ColumnsType<ActivityProps> = [
    {
      title: 'ID da Atividade',
      align: 'left',
      dataIndex: '_id',
      key: 'id',
      // defaultSortOrder: 'ascend',
      // sortDirections: ['ascend', 'descend', 'ascend'],
      // sorter: (a, b) => a.contact.name.localeCompare(b.contact.name),
      render: (id: string) => (
        <Typography.Text copyable={{ text: id }}>
          {id.substring(0, 8)}
        </Typography.Text>
      ),
    },
    {
      title: translate('MaintenanceSystemTab.Title'),
      key: 'title',
      dataIndex: 'title',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('SystemTable.nameColumn'),
      key: 'system',
      dataIndex: 'system',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceActivity.SelectComponents'),
      key: 'component',
      dataIndex: 'component',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceSystemTab.Activities'),
      key: 'activity',
      dataIndex: 'activity',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceSystemTab.Competence'),
      dataIndex: 'competence',
      key: 'competence',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: translate('MaintenanceSystemTab.Responsibles'),
      key: 'responsible',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Typography.Text>
          {record.responsible
            ? record.responsible
            : 'Nenhum responsável informado.'}
        </Typography.Text>
      ),
    },
    {
      title: translate('MaintenanceActivity.InitialDate'),
      key: 'renovation_date',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record) => (
        <Typography.Text>
          {record.initialDate
            ? format(new Date(record?.initialDate), 'dd/MM/yyyy')
            : 'Nenhuma data informada'}
        </Typography.Text>
      ),
    },
    {
      title: translate('MaintenanceSystemTab.Periodicity'),
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: ActivityProps) => (
        <>
          {record.periodicity.type === 'day' ? (
            <>
              {record.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Day')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Days')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}

          {record.periodicity.type === 'sem' ? (
            <>
              {record.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Week')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Weeks')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}

          {record.periodicity.type === 'men' ? (
            <>
              {record.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Months')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.MonthsPlural')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}

          {record.periodicity.type === 'anu' ? (
            <>
              {record.periodicity.interval === 1 ? (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Year')}
                </Text>
              ) : (
                <Text>
                  {translate('MaintenanceSystemTab.Text')}{' '}
                  {record.periodicity.interval}{' '}
                  {translate('MaintenanceSystemTab.Years')}
                </Text>
              )}
            </>
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      title: translate('TasksRegistrationPauseTable.actionsColumn'),
      align: 'center',
      key: 'remove',
      render: (record: ActivityProps) => (
        <Space size="middle">
          <TasksActivityMaintenance activity={record} />
          <ViewActivity activity={record} />
          {/* <UpdateSystem data={record} /> */}
          <Popconfirm
            title={translate('MaintenanceSystemTab.ConfirmDeleteActivity')}
            onConfirm={() => deleteActivity(record._id)}
          >
            <Tooltip
              title={translate('TasksRegistrationPauseTable.deleteTooltip')}
            >
              <Button ghost danger shape="circle" icon={<DeleteFilled />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Card
      bordered={false}
      title={translate('MaintenanceSystemTab.Activities')}
      extra={<AddActivityModal />}
      style={{
        display: 'inline-block',
        width: '100%',
        marginTop: 15,
      }}
    >
      <Table
        rowKey={(record) => record?._id}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
          size: 'large',
        }}
        size="small"
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={maintenance.activities}
        pagination={{
          showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
        }}
      />
    </Card>
  )
}

export default ActivityTab
