/* eslint-disable no-nested-ternary */
import {
  EditOutlined,
  LoadingOutlined,
  ShareAltOutlined,
} from '@ant-design/icons'
import {
  Card,
  Col,
  Row,
  Avatar,
  Typography,
  Space,
  Button,
  Tooltip,
} from 'antd'
import React from 'react'

import { FiFileText, FiMail, FiPhone, FiUser } from 'react-icons/fi'
import { FaWhatsapp } from 'react-icons/fa'

import { useParams, useHistory } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { useClients } from '~/hooks/Clients/useClients'

import {
  Container,
  Wrapper,
  ContAvatar,
  ContAboutClient,
  LoadingIndicator,
  OtherInfos,
} from './styles'
import AdditionalPhones from '../AditionalPhones'
import TalkToContact from '../TalkToContact'
// import { useAuth } from '~/hooks/Auth/AuthContext';
import { useListTask } from '~/hooks/Tasks/useListTasks'
import { createLetterAvatar } from '~/utils/letter'
import { stringToHslColor } from '~/utils/stringToHslColor'
import { translate } from '~/utils/locale'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { encode } from 'base-64'

const DetailsTab: React.FC = () => {
  const { customerId } = useParams<{ customerId: string }>()

  const { companiesData, selectedCompany } = useCompanies()

  const { clients, isLoading, setIsEdit } = useClients()
  const { handleUpdateFilters } = useListTask()
  // const { user } = useAuth();

  const user = companiesData?.[0].collaborator.contact.name

  const history = useHistory()

  const { Text } = Typography

  if (isLoading || !clients) {
    return (
      <LoadingIndicator>
        <LoadingOutlined style={{ fontSize: 64, margin: 'auto' }} spin />
      </LoadingIndicator>
    )
  }

  const nameEncoded = encode(
    clients?.contact.name ? clients?.contact.name : 'null',
  )
  const emailEncoded = encode(
    clients?.contact.email ? clients?.contact.email : 'null',
  )
  const phoneEncoded = encode(
    clients?.contact.phone ? clients?.contact.phone : 'null',
  )
  const companyNameEncoded = encode(
    clients?.company.name ? clients.company.name : 'null',
  )

  function handleFilterTaskClient() {
    const data = {
      customers_id: customerId,
    }

    handleUpdateFilters(data)
    history.push('/agenda')
  }

  return (
    <Container>
      <Card
        bordered={false}
        title={translate('ClientsDetailsTab.cardGeneralInfoTitle')}
        extra={
          <Space>
            <Tooltip
              title=" Compartilhe um site onde seu cliente pode abrir um chamado no Gstor. 
O chamado aberto pelo cliente será exibido em HelpDesk/Tickets"
            >
              <Button
                type="link"
                icon={<ShareAltOutlined />}
                target="_blank"
                href={`https://app.gstor.com.br/novoTicket/${selectedCompany}/client/${nameEncoded}/${emailEncoded}/${phoneEncoded}/${companyNameEncoded}`}
              >
                HelpDesk
              </Button>
            </Tooltip>
            <Button
              id="btn-view-task-client"
              type="primary"
              onClick={handleFilterTaskClient}
            >
              {translate('ClientsDetailsTab.seeTasksButton')}
            </Button>
            <Button
              id="btn-edit-client"
              type="primary"
              icon={<EditOutlined />}
              onClick={() => setIsEdit(true)}
            >
              {translate('ClientsDetailsTab.editButton')}
            </Button>
          </Space>
        }
        style={{
          display: 'inline-block',
          width: '100%',
        }}
      >
        <Wrapper>
          <ContAvatar>
            <Avatar
              shape="square"
              size={175}
              style={{
                fontSize: 40,
                background: '#EAEAEA',
                color: stringToHslColor(`${clients.contact.name}`),
              }}
            >
              {createLetterAvatar(`${clients.contact.name}`)}
            </Avatar>
          </ContAvatar>
          <ContAboutClient>
            <Row gutter={16}>
              <Col span={10}>
                <div className="text">
                  <div>
                    <FiUser />
                    <Text strong>
                      {translate('ClientsDetailsTab.nameLabel')}
                    </Text>
                  </div>
                  <Text>{clients.contact.name}</Text>
                </div>
              </Col>
              <Col span={10}>
                <div className="text">
                  <div>
                    <FiMail />
                    <Text strong>
                      {translate('ClientsDetailsTab.emailLabel')}
                    </Text>
                  </div>
                  {clients?.contact?.email === null ? (
                    <Text>{translate('ClientsDetailsTab.createdNull')}</Text>
                  ) : (
                    <Text>{clients?.contact?.email}</Text>
                  )}
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={10}>
                <div className="text">
                  <div>
                    <FiUser />
                    <Text strong>
                      {translate('ClientsDetailsTab.personTypeLabel')}
                    </Text>
                  </div>
                  {/* {console.log(clients.contact.person_type)} */}
                  {clients.contact.person_type === 'physicalPerson' ? (
                    <div>
                      <Text>
                        {translate('ClientsDetailsTab.physicalPersonType')}
                      </Text>
                    </div>
                  ) : clients.contact.person_type === 'legalPerson' ? (
                    <Text>
                      {translate('ClientsDetailsTab.juridicPersonType')}
                    </Text>
                  ) : (
                    <Text>{translate('ClientsDetailsTab.foreignType')}</Text>
                  )}
                </div>
              </Col>
              <Col span={5}>
                <div className="text">
                  <div>
                    <FiFileText />
                    <Text strong>
                      {clients.contact.person_type === 'foreign'
                        ? translate('ClientsDetailsTab.passportLabel')
                        : translate('ClientsDetailsTab.cpfLabel')}
                    </Text>
                  </div>
                  {clients?.contact.document === null ? (
                    <Text>{translate('ClientsDetailsTab.createdNull')}</Text>
                  ) : (
                    <Text>{clients.contact.formattedDocument}</Text>
                  )}
                </div>
              </Col>
              <Col span={5}>
                <div className="text">
                  <div>
                    <FiFileText />
                    <Text strong>
                      {clients.contact.person_type === 'physicalPerson'
                        ? translate('ClientsDetailsTab.idLabel')
                        : translate('ClientsDetailsTab.cnpjLabel')}
                    </Text>
                  </div>
                  {clients.contact.person_type !== 'physicalPerson' ? (
                    <>
                      {clients?.contact.cnpj === null ? (
                        <Text>
                          {translate('ClientsDetailsTab.createdNull')}
                        </Text>
                      ) : (
                        <Text>
                          <NumberFormat
                            value={clients.contact.cnpj}
                            displayType="text"
                            format="##.###.###/####-##"
                          />
                        </Text>
                      )}
                    </>
                  ) : (
                    <>
                      {clients?.contact.rg === null ? (
                        <Text>
                          {translate('ClientsDetailsTab.createdNull')}
                        </Text>
                      ) : (
                        <Text>{clients.contact.rg}</Text>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={10}>
                <div className="text">
                  <div>
                    {clients.contact.is_whatsapp ? <FaWhatsapp /> : <FiPhone />}
                    {clients.contact.is_whatsapp ? (
                      <Text strong>
                        {translate('ClientsDetailsTab.whatsappPhoneLabel')}
                      </Text>
                    ) : (
                      <Text strong>
                        {translate('ClientsDetailsTab.phoneLabel')}
                      </Text>
                    )}
                  </div>
                  {clients.contact.phone !== null ? (
                    <Text>{clients.contact.formattedPhone}</Text>
                  ) : (
                    <Text>{translate('ClientsDetailsTab.phoneNullLabel')}</Text>
                  )}
                </div>
              </Col>
              <Col span={10}>
                <div className="text">
                  <div>
                    <FiPhone />
                    <Text strong>
                      {translate('ClientsDetailsTab.additionalPhonesLabel')}
                    </Text>
                  </div>
                  <AdditionalPhones />
                </div>
              </Col>
            </Row>
          </ContAboutClient>
        </Wrapper>
      </Card>

      <Card
        bordered={false}
        title={translate('ClientsDetailsTab.cardOtherInfoTitle')}
        style={{
          display: 'inline-block',
          width: '100%',
          marginTop: 10,
        }}
      >
        <OtherInfos>
          <Row gutter={16}>
            <Col span={8}>
              <div className="text">
                <Text strong>
                  {translate('ClientsDetailsTab.createdLabel')}
                </Text>
                <div className="tags">
                  {user === undefined ? (
                    <Text>{translate('ClientsDetailsTab.createdNull')}</Text>
                  ) : (
                    <Text>{user}</Text>
                  )}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="text">
                <Text strong>
                  {translate('ClientsDetailsTab.corporateNameLabel')}
                </Text>
                {clients.corporate_name === null ? (
                  <Text>
                    {translate('ClientsDetailsTab.corporateNameNull')}
                  </Text>
                ) : (
                  <Text>{clients.corporate_name}</Text>
                )}
              </div>
            </Col>
            <Col span={8}>
              <div className="text">
                <Text strong>
                  {translate('ClientsDetailsTab.segmentLabel')}
                </Text>
                <div className="tags">
                  {clients.segment !== null ? (
                    <Text>{clients.segment?.title}</Text>
                  ) : (
                    <Text>{translate('ClientsDetailsTab.segmentNull')}</Text>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <div className="text">
                <Text strong>
                  {translate('ClientsDetailsTab.externalCodeLabel')}
                </Text>
                {clients.external_code === null ? (
                  <Text>{translate('ClientsDetailsTab.externalCodeNull')}</Text>
                ) : (
                  <Text>{clients.external_code}</Text>
                )}
              </div>
            </Col>
            <Col span={8}>
              <div className="text">
                <Text strong>
                  {translate('ClientsDetailsTab.talkToContactLabel')}
                </Text>
                <TalkToContact />
              </div>
            </Col>
            <Col span={8}>
              <div className="text">
                <Text strong>
                  {translate('ClientsDetailsTab.responsibleLabel')}
                </Text>
                <div className="tags">
                  {clients?.collaborator?.contact?.name === null ||
                  clients?.collaborator === null ? (
                    <Text>
                      {translate('ClientsDetailsTab.responsibleNull')}
                    </Text>
                  ) : (
                    <Text>{clients?.collaborator?.contact?.name}</Text>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={20}>
              <div className="text">
                <Text strong>
                  {translate('ClientsDetailsTab.observationLabel')}
                </Text>
                {clients.notes === null ? (
                  <Text>{translate('ClientsDetailsTab.observationNull')}</Text>
                ) : (
                  <div className="notes">
                    <Text>{clients.notes}</Text>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </OtherInfos>
      </Card>
    </Container>
  )
}

export default DetailsTab
