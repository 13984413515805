import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Layout, Menu, Grid, Row, Tooltip } from 'antd'
import {
  ApartmentOutlined,
  AppstoreOutlined,
  BorderInnerOutlined,
  BuildOutlined,
  CalendarOutlined,
  CarOutlined,
  CarryOutOutlined,
  ClusterOutlined,
  ContactsOutlined,
  DeploymentUnitOutlined,
  DollarOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  FormOutlined,
  IdcardOutlined,
  LayoutOutlined,
  MoneyCollectOutlined,
  PartitionOutlined,
  PicLeftOutlined,
  ScheduleOutlined,
  SettingOutlined,
  // ScheduleOutlined,
  TeamOutlined,
  ToolOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons'
import SubMenu from 'antd/lib/menu/SubMenu'

import { Logo, Container } from './styles'

import LogoGestor from '~/assets/logo_icon.svg'
import { translate } from '~/utils/locale'

const { Sider } = Layout

interface SidebarProps {
  isCollapsed: boolean
  onCollapseSidebar: () => void
}

const { useBreakpoint } = Grid

const Sidebar: React.FC<SidebarProps> = ({
  isCollapsed,
  onCollapseSidebar,
}) => {
  const location = useLocation()
  const screens = useBreakpoint()

  const localização = location.pathname.split('/')[1]

  const enabledCompany = localStorage.getItem('@Gstor:enabledCompany')

  return (
    <Row>
      <Container>
        <Sider
          trigger={screens.sm && null}
          breakpoint="lg"
          collapsedWidth={screens.xs ? 0 : 55}
          collapsed={!isCollapsed}
          onCollapse={onCollapseSidebar}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'sticky',
            top: 0,
            left: 0,
            boxShadow: 'rgba(0, 0, 0, 0.281) 0px 5px 15px',
            borderRadius: '0 0 0.3rem 0',
            background: 'rgb(10, 11, 12)',
          }}
          theme="dark"
        >
          {isCollapsed ? (
            <Logo>
              <img src={LogoGestor} alt="Logo Gstor" />
              <h2>Gstor</h2>
            </Logo>
          ) : (
            <Logo>
              <img src={LogoGestor} alt="Logo Gstor" />
            </Logo>
          )}

          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['/']}
            defaultOpenKeys={['dashboard']}
            selectedKeys={[localização]}
          >
            {enabledCompany === 'true' ? (
              <>
                {/* <Tooltip title="Em Manutenção, Disponível em Breve!"> */}
                <SubMenu
                  key="Dashboard"
                  title={translate('Sidebar.dashboard')}
                  // disabled
                  icon={<LayoutOutlined />}
                >
                  <Menu.ItemGroup>
                    <Menu.Item key="dashboard" icon={<LayoutOutlined />}>
                      <Link to="/dashboard">
                        {translate('Sidebar.dashboard')}
                      </Link>
                    </Menu.Item>
                  </Menu.ItemGroup>
                </SubMenu>

                <SubMenu
                  key="helpdesk"
                  icon={<SettingOutlined />}
                  title="HelpDesk"
                >
                  <Menu.Item key="tiposSolicitacao" icon={<UserOutlined />}>
                    <Link to="/tiposSolicitacao">
                      {translate('Sidebar.solicitationType')}
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tickets" icon={<UserOutlined />}>
                    <Link to="/helpdesk">Tickets</Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="Tarefas"
                  title={translate('Sidebar.taskTitle')}
                  icon={<FileProtectOutlined />}
                >
                  <Menu.ItemGroup>
                    <Menu.Item
                      key="dashboardTasks"
                      icon={<FileProtectOutlined />}
                    >
                      <Link to="/dashboardTasks">
                        {translate('Sidebar.dashboardTasks')}
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="agenda" icon={<CalendarOutlined />}>
                      <Link to="/agenda">
                        {translate('Sidebar.taskSchedule')}
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="tarefas" icon={<FileProtectOutlined />}>
                      <Link to="/tarefas">{translate('Sidebar.taskList')}</Link>
                    </Menu.Item>
                    <Menu.Item key="kanban" icon={<FileProtectOutlined />}>
                      <Link to="/kanban">
                        {translate('Sidebar.taskKanban')}
                      </Link>
                    </Menu.Item>

                    <Menu.SubMenu
                      key="configuracoes"
                      title={translate('Sidebar.settingsTitle')}
                      icon={<SettingOutlined />}
                    >
                      <Menu.Item key="tiposDeTarefas" icon={<ToolOutlined />}>
                        <Link to="/tiposDeTarefas">
                          {translate('Sidebar.taskType')}
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="formularios" icon={<FormOutlined />}>
                        <Link to="/formularios">Formularios</Link>
                      </Menu.Item>
                    </Menu.SubMenu>

                    {/* <Menu.Item
                      key="motivosDePausa"
                      icon={<DisconnectOutlined />}
                    >
                      <Link to="/motivosDePausa">
                        {translate('Sidebar.taskBreakReasons')}
                      </Link>
                    </Menu.Item> */}
                  </Menu.ItemGroup>
                </SubMenu>

                <SubMenu
                  key="colaborador"
                  title={translate('Sidebar.collaboratorsTitle')}
                  icon={<UsergroupAddOutlined />}
                >
                  <Tooltip title="Em breve!">
                    <Menu.Item
                      key="dashboardContributors"
                      icon={<UsergroupAddOutlined />}
                      disabled
                    >
                      <Link to="/dashboardContributors">
                        {translate('Sidebar.dashboardContributors')}
                      </Link>
                    </Menu.Item>
                  </Tooltip>

                  <Menu.Item
                    key="colaboradores"
                    icon={<UsergroupAddOutlined />}
                  >
                    <Link to="/colaboradores">
                      {translate('Sidebar.collaboratorsList')}
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="equipes" icon={<TeamOutlined />}>
                    <Link to="/equipes">
                      {translate('Sidebar.collaboratorsTeams')}
                    </Link>
                  </Menu.Item>

                  <Menu.SubMenu
                    key="configuracoes"
                    title={translate('Sidebar.settingsTitle')}
                    icon={<SettingOutlined />}
                  >
                    <Menu.Item key="habilidades" icon={<IdcardOutlined />}>
                      <Link to="/habilidades">
                        {translate('Sidebar.collaboratorsSkills')}
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="escalas" icon={<CalendarOutlined />}>
                      <Link to="/escalas">
                        {translate('Sidebar.collaboratorsScales')}
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="status" icon={<ScheduleOutlined />}>
                      <Link to="/status">
                        {translate('Sidebar.collaboratorsStatus')}
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="usuarios" icon={<UserOutlined />}>
                      <Link to="/usuarios">
                        {translate('Sidebar.settingsLogin')}
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="cracha" icon={<UserOutlined />}>
                      <Link to="/cracha">
                        {translate('Sidebar.badgeTitle')}
                      </Link>
                    </Menu.Item>
                  </Menu.SubMenu>
                </SubMenu>

                <SubMenu
                  key="cliente"
                  title={translate('Sidebar.clientsTitle')}
                  icon={<UserOutlined />}
                >
                  <Tooltip title="Em breve!">
                    <Menu.Item
                      key="dashboardCustomers"
                      icon={<UserOutlined />}
                      disabled
                    >
                      <Link to="/dashboardCustomers">
                        {translate('Sidebar.dashboardCustomers')}
                      </Link>
                    </Menu.Item>
                  </Tooltip>

                  <Menu.Item key="clientes" icon={<UserOutlined />}>
                    <Link to="/clientes">
                      {translate('Sidebar.clientsList')}
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="clientePrincipal" icon={<UserOutlined />}>
                    <Link to="/clientePrincipal">
                      {translate('Sidebar.clientsParent')}
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="contatosCliente" icon={<ContactsOutlined />}>
                    <Link to="/contatosCliente">
                      {translate('Sidebar.clientsContacts')}
                    </Link>
                  </Menu.Item>

                  <Menu.SubMenu
                    key="configuracoes"
                    title={translate('Sidebar.settingsTitle')}
                    icon={<SettingOutlined />}
                  >
                    <Menu.Item key="segmentos" icon={<PartitionOutlined />}>
                      <Link to="/segmentos">
                        {translate('Sidebar.clientsSegments')}
                      </Link>
                    </Menu.Item>
                  </Menu.SubMenu>
                </SubMenu>

                <SubMenu
                  key="financial"
                  title={translate('Sidebar.financialTitle')}
                  icon={<MoneyCollectOutlined />}
                >
                  <Menu.Item key="values" icon={<DollarOutlined />}>
                    <Link to="/financeiro/valoresAcordados">
                      {translate('Sidebar.financialAgreed')}
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="valuesReceive" icon={<DollarOutlined />}>
                    <Link to="/financeiro/valoresReceber">
                      {translate('Sidebar.financialReceive')}
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="valuesPay" icon={<DollarOutlined />}>
                    <Link to="/financeiro/valoresPagar">
                      {translate('Sidebar.financialAmount')}
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="equipamento"
                  title={translate('Sidebar.equipmentsTitle')}
                  icon={<BuildOutlined />}
                >
                  <Menu.Item key="equipamentos" icon={<BuildOutlined />}>
                    <Link to="/equipamentos">
                      {translate('Sidebar.equipmentsList')}
                    </Link>
                  </Menu.Item>

                  <Menu.SubMenu
                    key="configuracoes"
                    title={translate('Sidebar.settingsTitle')}
                    icon={<SettingOutlined />}
                  >
                    <Menu.Item key="categorias" icon={<PicLeftOutlined />}>
                      <Link to="/categorias">
                        {translate('Sidebar.equipmentsCategories')}
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="templates" icon={<BorderInnerOutlined />}>
                      <Link to="/templates">
                        {translate('Sidebar.equipmentsTemplates')}
                      </Link>
                    </Menu.Item>
                  </Menu.SubMenu>
                </SubMenu>

                <SubMenu
                  key="maintenanceTitle"
                  title={translate('Sidebar.maintenance')}
                  icon={<ApartmentOutlined />}
                >
                  <Menu.Item
                    title={translate('Sidebar.maintenance')}
                    key="maintenance"
                    icon={<AppstoreOutlined />}
                  >
                    <Link to="/manutencao">
                      {translate('Sidebar.maintenance')}
                    </Link>
                  </Menu.Item>

                  <Menu.Item
                    title={translate('Sidebar.systems')}
                    key="systems"
                    icon={<ClusterOutlined />}
                  >
                    <Link to="/manutencao/sistemas">
                      {translate('Sidebar.systems')}
                    </Link>
                  </Menu.Item>

                  <Menu.Item
                    title="Atividades"
                    key="activities"
                    icon={<ClusterOutlined />}
                  >
                    <Link to="/atividades">
                      {translate('Sidebar.activity')}
                    </Link>
                  </Menu.Item>

                  <Menu.Item
                    title="Databook"
                    key="databook"
                    icon={<ClusterOutlined />}
                  >
                    <Link to="/manutencao/databook">Databook</Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="relatorios"
                  title={translate('Sidebar.reportsTitle')}
                  icon={<FileTextOutlined />}
                >
                  <Menu.Item
                    title={translate('Sidebar.reportsTasksTitle')}
                    key="relatorioTarefas"
                    icon={<CarryOutOutlined />}
                  >
                    <Link to="/relatorioTarefas">
                      {translate('Sidebar.reportsTasks')}
                    </Link>
                  </Menu.Item>

                  <Menu.Item
                    title={translate('Sidebar.reportsKmTitle')}
                    key="relatorioQuilometros"
                    icon={<CarOutlined />}
                  >
                    <Link to="/relatorioQuilometros">
                      {translate('Sidebar.reportsKm')}
                    </Link>
                  </Menu.Item>
                </SubMenu>

                <Menu.Item key="permissoes" icon={<DeploymentUnitOutlined />}>
                  <Link to="/permissoes">
                    {translate('Sidebar.settingsPermissions')}
                  </Link>
                </Menu.Item>
              </>
            ) : (
              <Menu.Item key="dashboard" icon={<LayoutOutlined />}>
                <Link to="/dashboard">{translate('Sidebar.dashboard')}</Link>
              </Menu.Item>
            )}
          </Menu>
        </Sider>
      </Container>
    </Row>
  )
}

export default Sidebar
