/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Modal,
  Button,
  Form,
  Row,
  Input,
  Col,
  Radio,
  Select,
  Space,
} from 'antd'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useListClients } from '~/hooks/Clients/useListClients'
import { useCompanies } from '~/hooks/Companies/useCompanies'
import { useListContributors } from '~/hooks/Contributors/useListContributors'

import { useMovements } from '~/hooks/Equipments/Movements/useMovements'
import { translate } from '~/utils/locale'
import { ContainerButton } from '../../styles'

interface MovementsData {
  customer_id: string
  collaborator_id: string
  notes: string
}

const CreateMoviment: React.FC = () => {
  const { createMovements } = useMovements()
  const { selectedCompany, companiesData } = useCompanies()

  const { clients } = useListClients()
  const { data } = useListContributors()

  const [stockId, setStockId] = useState<string>()

  const { equipmentId } = useParams<{ equipmentId: string }>()

  const { Option } = Select

  const [value, setValue] = useState<number>()
  const [isModalVisible, setIsModalVisible] = useState(false)

  function handleMovement(e: any) {
    setValue(e.target.value)

    const companySelect = companiesData?.filter((c) => {
      return c.company.id === selectedCompany
    })

    const stock = companySelect?.map((c) => {
      return c.company.stocks.map((s) => {
        setStockId(s.id)
        return s.id
      })
    })

    return stock
  }

  function onCreateMovement(valueForm: MovementsData) {
    try {
      const movementData = {
        equipment_id: equipmentId,
        customer_id: valueForm.customer_id,
        collaborator_id: valueForm.collaborator_id,
        stock_id: stockId,
        notes: valueForm.notes,
        other: value === 4,
      }
      createMovements(movementData)
      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {translate('EquipmentsTabsMovementsCreate.addButton')}
      </Button>
      <Modal
        title={translate('EquipmentsTabsMovementsCreate.modalTitle')}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="700px"
        style={{ top: 60 }}
        footer={[
          <Space key="buttons">
            <Button key="back" onClick={closeModal}>
              {translate('EquipmentsTabsMovementsCreate.closeButton')}
            </Button>
            <ContainerButton>
              <Button
                key="save"
                type="primary"
                form="onCreateMovement"
                htmlType="submit"
                id="btn_create_movement_equipment"
              >
                {translate('EquipmentsTabsMovementsCreate.saveButton')}
              </Button>
            </ContainerButton>
          </Space>,
        ]}
      >
        <Form
          layout="vertical"
          onFinish={onCreateMovement}
          id="onCreateMovement"
        >
          <Row>
            <Form.Item>
              <Radio.Group onChange={handleMovement}>
                <Radio value={1}>
                  {translate('EquipmentsTabsMovementsCreate.toCustomer')}
                </Radio>
                <Radio value={2}>
                  {translate('EquipmentsTabsMovementsCreate.toContributor')}
                </Radio>
                <Radio value={3}>
                  {translate('EquipmentsTabsMovementsCreate.toInventory')}
                </Radio>
                <Radio value={4}>Outro</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
          <Row gutter={10}>
            <Col span={16}>
              {value === 1 ? (
                <Form.Item
                  label={translate(
                    'EquipmentsTabsMovementsCreate.customerLabel',
                  )}
                  name="customer_id"
                >
                  <Select
                    placeholder={translate(
                      'EquipmentsTabsMovementsCreate.customerPlaceholder',
                    )}
                  >
                    {clients?.map((client) => (
                      <Option value={client.id} key={client.id}>
                        {client.contact.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}

              {value === 2 ? (
                <Form.Item
                  label={translate(
                    'EquipmentsTabsMovementsCreate.contributorLabel',
                  )}
                  name="collaborator_id"
                >
                  <Select
                    placeholder={translate(
                      'EquipmentsTabsMovementsCreate.contributorPlaceholder',
                    )}
                  >
                    {data?.map((contribuitor) => (
                      <Option value={contribuitor.id} key={contribuitor.id}>
                        {contribuitor.contact.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={translate(
                  'EquipmentsTabsMovementsCreate.descriptionLabel',
                )}
                name="notes"
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default CreateMoviment
