import { FileOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Modal, Table, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useShowMaintenance } from '~/hooks/Maintenance/useShowMaintenance'
import { translate } from '~/utils/locale'

// import { Container } from './styles';

interface ActivityProps {
  _id: string
  title: string
  createdAt: string
  component?: string
  activity?: string
  competence?: string
  periodicity: {
    type: string
    interval: number
    date: Date
  }
  initialDate: Date
  responsible: string
  data_font: string
  tasks: [
    {
      taskId: string
      created: Date
    },
  ]
}

interface TaskProps {
  taskId: string
  created: Date
  status?: string
  plan?: string
}

interface Activity {
  activity: ActivityProps
}

const TasksActivityMaintenance: React.FC<Activity> = ({ activity }) => {
  const { isFetching } = useShowMaintenance()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const columns: ColumnsType<TaskProps> = [
    {
      title: 'Id da tarefa',
      key: 'taskId',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: TaskProps) => (
        <Typography.Link
          copyable={{ text: record.taskId }}
          href={`/tarefas/visualizar/${record.taskId}`}
        >
          {record.taskId.substring(0, 8)}
        </Typography.Link>
      ),
    },
    {
      title: 'Data de Criação',
      key: 'create',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (record: TaskProps) => (
        <Typography.Text>
          {format(new Date(record.created), `dd/MM/yyyy`)}
        </Typography.Text>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Plano da Atividade',
      key: 'plan',
      dataIndex: 'plan',
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ]

  const showModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Tooltip title="Ver tarefas da atividade">
        <Button
          ghost
          type="primary"
          shape="circle"
          icon={<FileOutlined />}
          onClick={showModal}
        />
      </Tooltip>
      <Modal
        title={`${translate('MaintenanceSystemTab.TaskModalTitle')} - ${
          activity.title
        }`}
        open={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        width="900px"
        footer={[
          <Button key="back" onClick={closeModal}>
            {translate('TasksCreateModalClient.closeButton')}
          </Button>,
        ]}
      >
        <Table
          rowKey={(record) => record?.taskId}
          loading={{
            indicator: <LoadingOutlined />,
            spinning: isFetching,
            size: 'large',
          }}
          columns={columns}
          pagination={{
            defaultPageSize: 5,
          }}
          size="middle"
          dataSource={activity.tasks}
          scroll={{ x: 1000 }}
        />
      </Modal>
    </>
  )
}

export default TasksActivityMaintenance
